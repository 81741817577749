<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <v-icon
      v-if="isSnapshotEnabledAndImageIsNotQcow2"
      class="material-icons"
      color="red"
    >
      warning
    </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    workload: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isSnapshotEnabledAndImageIsNotQcow2() {
      const version = this.workload.versions
        ? this.workload.versions.find((n) => n.name === this.tableItem.name) : {};
      if (!version || !version.workloadProperties) {
        return false;
      }
      const snapshotProperties = version.workloadProperties.snapshot;
      if (!snapshotProperties || !snapshotProperties.enabled) {
        return false;
      }
      // Only consider the case when the workload is fetch from DB
      // In other cases the files format is changed
      if (!version.files || !Array.isArray(version.files) || !version.files.length) {
        return false;
      }
      return !version.files.find((file) => file.type === '.qcow2' || file.containFileType === '.qcow2');
    },
  },
};
</script>
